import {
  Box,
  Flex,
  SkeletonText,
} from '@chakra-ui/react'
import {
  useJsApiLoader,
} from '@react-google-maps/api'
import { MultiRouteMap } from "./MultiRouteMap";
import { useParams } from 'react-router-dom'
import { useRef, useState, useEffect, useCallback } from 'react'
//import { decode } from "@googlemaps/polyline-codec";
import {getRoutePlan} from './RoutesAPI'
import { isMobile } from 'react-device-detect';

const coreProps = {
  apiUrl: process.env.REACT_APP_BASE_URL, 
  authToken: process.env.REACT_APP_AUTH_TOKEN};

const libraries = ['places', 'drawing', 'marker', 'core'];

function RoutePlanMap() {
  const { routePlanId, organizationId } = useParams();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  })

//  const kMetersToMiles = 0.621371 / 1000.0;

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [center] = useState({ lat: 35.1, lng: -82.1})
  const [routePlan, setRoutePlan] = useState(null)
  const [numRouteOptions, setNumRouteOptions] = useState(0)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [directionsRenderers, setDirectionsRenderers] = useState([])
  const [isLocalMobile] = useState(isMobile);
  const [windowWidth, setWindowWidth] = useState('100%');
  const [windowHeight, setWindowHeight] = useState('100%');
  const [currentLocation, setCurrentLocation] = useState({ lat: 35.1, lng: -82.1 })

  const handleWindowSizeChange = () => {
    const wString = ''+window.innerWidth+'px';
    const hString = ''+window.innerHeight+'px';
    setWindowWidth(wString);
    setWindowHeight(hString);
    window.location.reload(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if (map && routePlanId && !routePlan) {
    loadRoutePlan(routePlanId)
  }

  if (!isLoaded) {
    return <SkeletonText />
  }
  
  function setGoogleMap(googleMap)
  {
    setMap(googleMap);
  }

  async function loadRoutePlan(routePlanId) 
  {
    const response = await getRoutePlan({...coreProps, routePlanId: routePlanId, organizationId: organizationId})
    console.log(response);
    if (response.routePlan) {
      const rtePlan = response.routePlan;
//      setRoute(response.route);
      setRoutePlan(rtePlan);
      setNumRouteOptions(rtePlan.routeOptions.length);

      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      const originString = rtePlan.origin.latitude + ','+rtePlan.origin.longitude;
      const destinationString = rtePlan.destination.latitude + ','+rtePlan.destination.longitude;
      const waypoints = [];

      rtePlan.routeStops.forEach((s) => {
        waypoints.push({location:(s.stop.latitude + ',' + s.stop.longitude)});
      })      
      
      const results = await directionsService.route({
        origin: originString,
        destination: destinationString,
        waypoints: waypoints,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      setDirectionsResponse(results)
      setCurrentLocation({lat: rtePlan.origin.latitude, lng: rtePlan.origin.longitude})

//      clearRoute();

//      const primaryRoute = rtePlan.routeOptions[0];
//      setDistance(Number(primaryRoute.distanceInMeters * kMetersToMiles).toFixed(2) + ' miles')
//      setDuration(Number(primaryRoute.durationInSeconds / 60.0).toFixed(2) + ' mins');

      for (let i = rtePlan.routeOptions.length - 1; i >= 0 ; i--) {
        let strokeColor = '#ff0000';
        let opacity = 0.8;
        if (i > 0)
          strokeColor = '#808080';

        // eslint-disable-next-line no-undef
        const directionRenderer = new google.maps.DirectionsRenderer();
        directionRenderer.setMap(map);
        directionRenderer.setDirections(results);
        directionRenderer.setRouteIndex(i);
        directionRenderer.setOptions({ polylineOptions: { strokeColor: strokeColor, strokeOpacity: opacity } });

        directionsRenderers.push(directionRenderer);
      }
      setDirectionsRenderers(directionsRenderers);
    }
  };
    
/*
  function centerOnLocation()
  {
    if (selectedPlace) {
      map.setCenter(selectedPlace.geometry.location)
      if (selectedPlaceMarker) {
        selectedPlaceMarker.setMap(null)
        selectedPlaceMarker.position = selectedPlace.geometry.location;
        selectedPlaceMarker.icon = selectedPlace.icon;
        selectedPlaceMarker.setMap(map)
        setSelectedPlaceMarker(selectedPlaceMarker)
    } else {
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: selectedPlace.geometry.location, 
          map,
          icon: selectedPlace.icon,
        });  
        setSelectedPlaceMarker(marker)
      }
    }
  }
*/
    return (
    <Flex position='relative' flexDirection='column' alignItems='center' h='100vh' w='100vw' >
      <Box position='absolute' left={0} top={0} w={windowWidth} h={windowHeight}>
        <MultiRouteMap {...coreProps} center={center} currentLocation={currentLocation} isMobile={isLocalMobile} onLoad={setGoogleMap} directionsResponse={directionsResponse} numRouteOptions={numRouteOptions} />
      </Box>
    </Flex>
  )
}

export default RoutePlanMap
