export const getAssetsForRoute = async (props) => {
  const { apiUrl, authToken, organizationId, routeId, lastAssetTimestamp } = props
  const bodyMap = { 
          organizationId: organizationId,
          routeId: routeId,
          fromTimestamp: lastAssetTimestamp,
        }
  const results = await fetch(`${apiUrl}salesforce/assets/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
    body: JSON.stringify(bodyMap)
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err)
    })
  return results
}

export const getAsset = async (props) => {
  const { apiUrl, authToken, organizationId, assetId, lastAssetTimestamp } = props
  const bodyMap = { 
          organizationId: organizationId,
          includeDevices: true,
          fromRouteMap: true,
          fromTimestamp: lastAssetTimestamp,
        }
  const results = await fetch(`${apiUrl}salesforce/assets/get/${assetId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
    body: JSON.stringify(bodyMap)
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err)
    })
  return results
}

export const getAssetsByAppUser = async (props) => {
  const { apiUrl, authToken, appUserId, lastAssetTimestamp } = props
  const bodyMap = { 
          includeDevices: false,
          fromRouteMap: true,
          fromTimestamp: lastAssetTimestamp,
          appUserId: appUserId,
        }
  const results = await fetch(`${apiUrl}salesforce/assets/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
    body: JSON.stringify(bodyMap)
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err)
    })
  return results
}

export const getAssetsByFlagged = async (props) => {
  const { apiUrl, authToken, flagged, organizationId, lastAssetTimestamp } = props
  const bodyMap = { 
          includeDevices: false,
          fromRouteMap: true,
          fromTimestamp: lastAssetTimestamp,
          flagged: flagged,
          organizationId: organizationId,
        }
  const results = await fetch(`${apiUrl}salesforce/assets/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
    body: JSON.stringify(bodyMap)
  })
    .then((response) => response.json())
    .then((json) => {
console.log('JSON: '+JSON.stringify(json));
      return json;
    })
    .catch((err) => {
      console.log(err)
    })
  return results
}

