import {
  Box,
  Flex,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
//import { FaLocationArrow, FaSearch } from 'react-icons/fa'
import {
  useJsApiLoader,
} from '@react-google-maps/api'
import { MultiRouteMap } from "./MultiRouteMap";
import { useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
//import { decode } from "@googlemaps/polyline-codec";
import { getAsset } from './AssetsAPI'
import { isMobile } from 'react-device-detect';

const coreProps = {
  apiUrl: process.env.REACT_APP_BASE_URL, 
  authToken: process.env.REACT_APP_AUTH_TOKEN};

const libraries = ['places', 'drawing', 'marker', 'core'];

function AssetMap() {
  const { assetId, organizationId } = useParams();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  })

  const kMetersToMiles = 0.621371 / 1000.0;

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [offRoute, setOffRoute] = useState('')
  const [heading, setHeading] = useState('')
  const [altitude, setAltitude] = useState('')
  const [speed, setSpeed] = useState('')
  const [currentLocation, setCurrentLocation] = useState({ lat: 35.1, lng: -82.1 })
  const [center, setCenter] = useState({ lat: 35.1, lng: -82.1})
  const [routePlan, setRoutePlan] = useState(null)
  const [numRouteOptions, setNumRouteOptions] = useState(0)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [directionsRenderers, setDirectionsRenderers] = useState([])
  const [assetMap, setAssetMap] = useState(new Map());
  const [lastAssetTimestamp, setLastAssetTimestamp] = useState(1672531200000);  // default to 1/1/2023 00:00:00
  const [isLocalMobile, setIsLocalMobile] = useState(isMobile);
//  const [isLocalMobile, setIsLocalMobile] = useState(true);
  const [detailsBackground, setDetailsBackground] = useState('#80ff8080');
  const [windowWidth, setWindowWidth] = useState('100%');
  const [windowHeight, setWindowHeight] = useState('100%');

  const logoMap = {
    'Chris Janko': 'https://findjanko.locatorx.com/LXMarkerCJ.png',
    'Jeff Kase': 'https://findjanko.locatorx.com/LXMarkerJK.png',
    'Tom Pringle': 'https://findjanko.locatorx.com/LXMarkerTP.png',
    'other': 'https://findjanko.locatorx.com/LXMarker.png',
  }
  const logoWarningMap = {
    'Chris Janko': 'https://findjanko.locatorx.com/LXMarkerCJ.warning.png',
    'Jeff Kase': 'https://findjanko.locatorx.com/LXMarkerJK.warning.png',
    'Tom Pringle': 'https://findjanko.locatorx.com/LXMarkerTP.warning.png',
    'other': 'https://findjanko.locatorx.com/LXMarker.warning.png',
  }
  const logoErrorMap = {
    'Chris Janko': 'https://findjanko.locatorx.com/LXMarkerCJ.error.png',
    'Jeff Kase': 'https://findjanko.locatorx.com/LXMarkerJK.error.png',
    'Tom Pringle': 'https://findjanko.locatorx.com/LXMarkerTP.error.png',
    'other': 'https://findjanko.locatorx.com/LXMarker.error.png',
  }

  const handleWindowSizeChange = () => {
    const wString = ''+window.innerWidth+'px';
    const hString = ''+window.innerHeight+'px';
    setWindowWidth(wString);
    setWindowHeight(hString);
    window.location.reload(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const fetchData = useCallback(async () => {

    const theTime = Date.now();
    console.log('fetchData at '+theTime+" "+lastAssetTimestamp+" "+currentLocation.lat+","+currentLocation.lng+" window: "+window.innerWidth+","+window.innerHeight);
//    if (!routeId)
//      return;

    const results = await getAsset({...coreProps, assetId: assetId, organizationId: organizationId, lastAssetTimestamp: lastAssetTimestamp});

    if (results && results.asset) {
      console.log('**** have an asset update: '+results.asset.latitude+" "+results.asset.longitude);
//      results.assets.forEach((a) => {
//        let localName  = 'other';
//        let title = '';
        const a = results.asset;
        let iconMapSelect = logoMap;
        let detBgnd = '#80ff8080';
        let iconFile = iconMapSelect['other'];
//        let iconFile = ((localName in iconMapSelect) ? iconMapSelect[localName] : iconMapSelect['other']);
//        let iconFile = 'https://findjanko.locatorx.com/LXMarker.png';
        let marker;
        
          if (!assetMap.has(a.assetId)) {
            // new asset
            // eslint-disable-next-line no-undef
            marker = new google.maps.Marker({
              position: { lat: a.latitude, lng: a.longitude },  // state variable
              map,
             icon: iconFile,
            });
            const productMap = results.productMap;
            let contentString = '<table>';
            contentString += '<tr><td style="padding-right:5px">Asset Name:</td><td>'+a.tag+'</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Unique Code:</td><td>'+a.externalId+'</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Facility:</td><td>'+a.facility+'</td></tr>';
            if (a.productId != null) {
              const p = productMap[a.productId];
              contentString += '<tr><td style="padding-right:5px">Product:</td><td>'+p.name+'</td></tr>';
              contentString += '<tr><td style="padding-right:5px">Manufacturer:</td><td>'+p.manufacturerName+'</td></tr>';
            }
            // if (a.customMap) {
            //   for (let key in a.customMap) {
            //     contentString += '<tr><td style="padding-right:5px">'+key+'</td><td>'+a.customMap[key]+'</td></tr>';
            //   }
            // }
            // contentString += '</table>';
            // contentString += '<hr></hr>';
            // contentString += '<table>';
            // if (a.classificationMap) {
            //   for (let key in a.classificationMap) {
            //     contentString += '<tr><td style="padding-right:5px">'+key+'</td><td>'+a.classificationMap[key]+'</td></tr>';
            //   }
            // }
            contentString += '</table>';

            // eslint-disable-next-line no-undef
            let infoWindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: "",
            })
            marker.addListener("mouseover", () => {
              infoWindow.open({
                anchor: marker,
                map,
              })
            })
            marker.addListener("mouseout", () => {
              infoWindow.close()
            })
          } else {
            const aProperty = assetMap.get(a.assetId)
            marker = aProperty.marker
            marker.setMap(null)
            marker.position = { lat: a.latitude, lng: a.longitude };
            marker.icon = iconFile;
            marker.setMap(map)
          }

          const aMap = { position: { lat: a.latitude, lng: a.longitude }, marker: marker };
          if (a.propertiesMap) {
            aMap.heading = a.propertiesMap.heading;
            aMap.speed = a.propertiesMap.speed;
            aMap.altitude = a.propertiesMap.altitude;
            aMap.distanceFromRoute = a.propertiesMap.distanceFromRoute;

            showOurGuy(aMap)
          }
          map.setCenter({ lat: a.latitude, lng: a.longitude })
          assetMap.set(a.assetId, aMap)
          setAssetMap(assetMap);
          setCurrentLocation({ lat: a.latitude, lng: a.longitude });
          setLastAssetTimestamp(theTime);
          setDetailsBackground(detBgnd);
        
//      })
    }

//    setCurrentLocation({lat: (currentLocation.lat+0.01), lng: (currentLocation.lng+0.01)});
  }, [lastAssetTimestamp, currentLocation, assetId, assetMap, map])
  
  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      // make sure to catch any error
      .catch(console.error);

    }, 5000);
      
      return () => clearInterval(interval);

  }, [fetchData])

//  if (map && routeId && !routePlan) {
//    loadRoute(routeId)
//  }

  if (!isLoaded) {
    return <SkeletonText />
  }
  
  function setGoogleMap(googleMap)
  {
    setMap(googleMap);
  }
/*
  async function loadRoute(routeId) 
  {
    const response = await getRoute({...coreProps, routeId: routeId, organizationId: organizationId})
    console.log(response);
    if (response.route) {
      const rtePlan = response.route.routePlan;
//      setRoute(response.route);
      setRoutePlan(rtePlan);
      setNumRouteOptions(rtePlan.routeOptions.length);

      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      const originString = rtePlan.origin.latitude + ','+rtePlan.origin.longitude;
      const destinationString = rtePlan.destination.latitude + ','+rtePlan.destination.longitude;
      const results = await directionsService.route({
        origin: originString,
        destination: destinationString,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      setDirectionsResponse(results)
      setCurrentLocation({lat: rtePlan.origin.latitude, lng: rtePlan.origin.longitude})

//      clearRoute();

      const primaryRoute = rtePlan.routeOptions[0];
      setDistance(Number(primaryRoute.distanceInMeters * kMetersToMiles).toFixed(2) + ' miles')
      setDuration(Number(primaryRoute.durationInSeconds / 60.0).toFixed(2) + ' mins');

      for (let i = rtePlan.routeOptions.length - 1; i >= 0 ; i--) {
        let strokeColor = '#ff0000';
        let opacity = 0.8;
        if (i > 0)
          strokeColor = '#808080';

        // eslint-disable-next-line no-undef
        const directionRenderer = new google.maps.DirectionsRenderer();
        directionRenderer.setMap(map);
        directionRenderer.setDirections(results);
        directionRenderer.setRouteIndex(i);
        directionRenderer.setOptions({ polylineOptions: { strokeColor: strokeColor, strokeOpacity: opacity } });

        directionsRenderers.push(directionRenderer);
      }
      setDirectionsRenderers(directionsRenderers);
    }
  };
*/
  function showOurGuy(aMap)
  {
//    setTitle(aMap.name + ',' + aMap.title);
    setOffRoute(Number(aMap.distanceFromRoute).toFixed(2) + 'm');
    setHeading(aMap.heading + ' deg');
    setAltitude(aMap.altitude + ' feet');
    setSpeed(aMap.speed + ' mph');
  }

    return (
    <Flex position='relative' flexDirection='column' alignItems='center' h='100vh' w='100vw' >
      <Box position='absolute' left={0} top={0} w={windowWidth} h={windowHeight}>
        <MultiRouteMap {...coreProps} center={center} currentLocation={currentLocation} isMobile={isLocalMobile} onLoad={setGoogleMap} directionsResponse={directionsResponse} numRouteOptions={numRouteOptions} />
      </Box>

      <Box position='absolute' left={2} bottom={10} p={2} borderRadius='lg' m={2} bgColor={detailsBackground} shadow='base' minW='container.xsm' zIndex='1' >
        <VStack >
{/*          <Text pt={-2} fontSize='12px' fontWeight={'bold'}>{title} </Text> */}
          <Text pt={-2} fontSize='12px'>Heading: {heading} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Altitude: {altitude} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Speed: {speed} </Text>
          {/*          <Divider orientation='horizontal' colorScheme='black' size={4}/> */}
          <Text pt={-2} mt={-2} fontSize='12px'>Distance: {distance} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Duration: {duration} </Text>
          <Text pt={-2} mt={-2} fontSize='12px'>Off Route: {offRoute} </Text>
        </VStack>
      </Box>
    </Flex>
  )
}

export default AssetMap
