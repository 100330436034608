import React from 'react'
import ReactDOM from 'react-dom'
import AssetMap from './AssetMap'
import RouteMap from './RouteMap'
import RoutePlanMap from './RoutePlanMap'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ChakraProvider, theme } from '@chakra-ui/react'
import AssetMapByAppUser from './AssetMapByAppUser'
import AssetMapByFlagged from './AssetMapByFlagged'

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/route/:routeId/:organizationId" element={<RouteMap />} />
          <Route path="/routePlan/:routePlanId/:organizationId" element={<RoutePlanMap />} />
          <Route path="/asset/:assetId/:organizationId" element={<AssetMap />} />
          <Route path="/appUser/:appUserId" element={<AssetMapByAppUser />} />
          <Route path="/flagged/:flagged/:organizationId" element={<AssetMapByFlagged />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
