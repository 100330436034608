import {
  Box,
  Flex,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  useJsApiLoader,
} from '@react-google-maps/api'
import { MultiRouteMap } from "./MultiRouteMap";
import { useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { getAssetsByFlagged } from './AssetsAPI'
import { isMobile } from 'react-device-detect';

const coreProps = {
  apiUrl: process.env.REACT_APP_BASE_URL, 
  authToken: process.env.REACT_APP_AUTH_TOKEN};

const libraries = ['places', 'drawing', 'marker', 'core'];

function AssetMapByFlagged() {
  const { flagged, organizationId } = useParams();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  })

  //const kMetersToMiles = 0.621371 / 1000.0;

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [currentLocation, setCurrentLocation] = useState({ lat: 35.1, lng: -82.1 })
  const [center, setCenter] = useState({ lat: 35.1, lng: -82.1})
  const [numRouteOptions, setNumRouteOptions] = useState(0)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [assetMap, setAssetMap] = useState(new Map());
  const [lastAssetTimestamp, setLastAssetTimestamp] = useState(1672531200000);  // default to 1/1/2023 00:00:00
  const [isLocalMobile, setIsLocalMobile] = useState(isMobile);
  const [detailsBackground, setDetailsBackground] = useState('#80ff8080');
  const [windowWidth, setWindowWidth] = useState('100%');
  const [windowHeight, setWindowHeight] = useState('100%');

  const logoMap = {
    'other': 'https://findjanko.locatorx.com/LXMarker.png',
  }
 
  const facilityMap = {
    '870950b7-935f-4253-9ba7-0f6786a3636b': { address: '120 Independence Ln Suite B', city: 'Maitland, FL 32751' },
    '6725cc7f-a67f-4649-814c-6372e0ab168b': { address: '947 N Mills Ave', city: 'Orlando, FL 32803' },
    'ea344be0-0714-4e24-8560-bd8604f71626': { address: '3019 Edgewater Drive', city: 'Orlando, FL 32804' },
    '9455ac38-6a5a-494a-8576-01addfdaf1e6': { address: '5600 Lake Underhill Rd', city: 'Orlando, FL 32807' },
    'adea0db2-3f06-4b5f-87a7-45a15533d1ea': { address: '5298 Mill Stream Drive', city: 'Saint Cloud, FL 34771' },
    '99aaf901-059a-46a4-a4fb-0f8fdba1a463': { address: '3443 Edgewater Drive', city: 'Orlando, FL 32804' },
    '86620c17-8b88-4a9c-a74d-b0cf9d40f9f6': { address: '341 N Mills Ave', city: 'Orlando, FL 32803' },
    '48c1a957-520a-46c9-b6f5-111baf3fa9c7': { address: '3275 Dixie Hwy NE', city: 'Palm Bay, FL 32905' },
    '95cae30b-c57b-4162-b6ae-06d6d2f7e222': { address: '3114 Corrine Dr', city: 'Orlando, FL 32803' },
    '10dd2715-cea7-478c-9be5-2d57eed970f3': { address: '14387 Bridgewater Crossings Blvd 110', city: 'Windermere, FL 34786' },
    '509f87f6-22e4-42ef-aa40-1ac75bcb58ac': { address: '1904 Michigan Ave', city: 'Kissimmee, FL 34744' },
    'a01c41d0-af82-405f-a8b1-992316ce9a30': { address: '1817 S Ferncreek Ave', city: 'Orlando, FL 32806' },
    '89971875-8c12-44c6-a943-44c706bc2c17': { address: '119 N Central Ave 1007', city: 'Oviedo, FL 32765' },
    '52f156ff-9d04-4c9e-848d-9c8971e592e9': { address: '400 South Orange Avenue', city: 'Orlando, FL 32801' },
    'e393820e-a921-47b1-9f64-5e4f7d136bf5': { address: '9901 Hawaiian Ct', city: 'Orlando, FL 32819' },
    '824704be-49c2-4b17-945b-a7e78d49e6b0': { address: '5420 LB McLeod Road', city: 'Orlando, FL 32811' },
    'a334fb8d-140b-4e7d-9b61-ca6ea58e8b05': { address: '2300 Howland Blvd', city: 'Deltona, FL 32738' }
  }

  const handleWindowSizeChange = () => {
    const wString = ''+window.innerWidth+'px';
    const hString = ''+window.innerHeight+'px';
    setWindowWidth(wString);
    setWindowHeight(hString);
    window.location.reload(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const fetchData = useCallback(async () => {

    const theTime = Date.now();
    console.log('fetchData at '+theTime+" "+lastAssetTimestamp+" "+currentLocation.lat+","+currentLocation.lng+" window: "+window.innerWidth+","+window.innerHeight);

    const results = await getAssetsByFlagged({...coreProps, flagged: flagged, organizationId: organizationId, lastAssetTimestamp: lastAssetTimestamp});

    if (results.assets && results.assets.length > 0) {
      console.log('**** have an asset update: '+results.assets[0].lastName+" "+results.assets[0].latitude+" "+results.assets[0].longitude);
        // eslint-disable-next-line no-undef
        var bounds = new google.maps.LatLngBounds();

        const productMap = results.productMap;

        results.assets.forEach((a) => {
        let iconMapSelect = logoMap;
        let detBgnd = '#80ff8080';
        let iconFile = iconMapSelect['other'];
        let marker;
        if (a.latitude != null && a.longitude != null) {
          if (!assetMap.has(a.assetId)) {
            // new asset
            // eslint-disable-next-line no-undef
            marker = new google.maps.Marker({
              position: { lat: a.latitude, lng: a.longitude },  // state variable
              map,
             icon: iconFile,
            });
            let contentString = '<table>';
//            contentString += '<tr><td style="padding-right:5px">Asset Name:</td><td>'+a.tag+'</td></tr>';
//            contentString += '<tr><td style="padding-right:5px">Unique Code:</td><td>'+a.externalId+'</td></tr>';
//            contentString += '<tr><td style="padding-right:5px">Description:</td><td>' + a.propertiesMap.description + '</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Description:</td><td>' + a.productTag + '</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Last Event:</td><td>' + a.lastEvent + '</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Facility:</td><td>'+a.facility+'</td></tr>';
            if (a.facilityId != null) {
              const locationMap = facilityMap[a.facilityId];
              if (locationMap != null) {
                contentString += '<tr><td style="padding-right:5px">Address:</td><td>'+locationMap.address+'</td></tr>';
                contentString += '<tr><td style="padding-right:5px">City:</td><td>'+locationMap.city+'</td></tr>';
              }
            }
            if (a.productId != null) {
              const p = productMap[a.productId];
              contentString += '<tr><td style="padding-right:5px">Product:</td><td>'+p.name+'</td></tr>';
              contentString += '<tr><td style="padding-right:5px">Manufacturer:</td><td>'+p.manufacturerName+'</td></tr>';
            }
            // if (a.customMap) {
            //   for (let key in a.customMap) {
            //     contentString += '<tr><td style="padding-right:5px">'+key+'</td><td>'+a.customMap[key]+'</td></tr>';
            //   }
            // }
            // contentString += '</table>';
            // contentString += '<hr></hr>';
            // contentString += '<table>';
            // if (a.classificationMap) {
            //   for (let key in a.classificationMap) {
            //     contentString += '<tr><td style="padding-right:5px">'+key+'</td><td>'+a.classificationMap[key]+'</td></tr>';
            //   }
            // }
            contentString += '</table>';

            // eslint-disable-next-line no-undef
            let infoWindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: "",
            })
            marker.addListener("mouseover", () => {
              infoWindow.open({
                anchor: marker,
                map,
              })
            })
            marker.addListener("mouseout", () => {
              infoWindow.close()
            })
          } else {
            const aProperty = assetMap.get(a.assetId)
            marker = aProperty.marker
            marker.setMap(null)
            marker.position = { lat: a.latitude, lng: a.longitude };
            marker.icon = iconFile;
            marker.setMap(map)
          }
          bounds.extend({ lat: a.latitude, lng: a.longitude });

          const aMap = { position: { lat: a.latitude, lng: a.longitude }, marker: marker };
          
          assetMap.set(a.assetId, aMap)
          setAssetMap(assetMap);
//          setCurrentLocation({ lat: a.latitude, lng: a.longitude });
          setLastAssetTimestamp(theTime);
          setDetailsBackground(detBgnd);
        
      }})
      map.fitBounds(bounds);
      
    } 
  }, [lastAssetTimestamp, currentLocation, assetMap, map])
  
  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      // make sure to catch any error
      .catch(console.error);

    }, 5000);
      
      return () => clearInterval(interval);

  }, [fetchData])

  if (!isLoaded) {
    return <SkeletonText />
  }
  
  function setGoogleMap(googleMap)
  {
    setMap(googleMap);
  }

     return (
    <Flex position='relative' flexDirection='column' alignItems='center' h='100vh' w='100vw' >
      <Box position='absolute' left={0} top={0} w={windowWidth} h={windowHeight}>
        <MultiRouteMap {...coreProps} center={center} currentLocation={currentLocation} isMobile={isLocalMobile} onLoad={setGoogleMap} directionsResponse={directionsResponse} numRouteOptions={numRouteOptions} />
      </Box>
    </Flex>
  )
}

export default AssetMapByFlagged
